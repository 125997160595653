import EmailButton from "./EmailButtonEvent"
import styles from './PageHeader.module.css'

function PageHeader() {
    return (
        <div className={styles.headerContainer}>
            <h1 className={styles.centeredTitle}>
                <a href="https://naridealize.com.br/">NAR Idealize</a>
            </h1>
            <EmailButton/>
        </div>
    )
}

export default PageHeader